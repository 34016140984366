import React, { useState } from 'react';
import axios from 'axios';
import styles from '../Styles/SubscriptionForm.module.css';
import Arrow from '../Assets/arrow.svg';

const SubscriptionForm = () => {
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowModal(false);

    const apiEndpoint = "https://api.ad-lante.com/Presents/Check";
    if (!apiEndpoint) {
      console.error(apiEndpoint);
      setErrorMessage('API endpoint is not defined');
      setShowModal(true);
      return;
    }

    axios.post(apiEndpoint, { present_code: code })
      .then(response => {
        if (response.data.message === "Ok") {
          window.location.href = response.data.redirect_url;
        }
      })
      .catch(error => {
        if (error.response) {
          console.error('Error response:', error.response);
          setErrorMessage(error.response.data.message || 'Error desconegut, torna-ho a provar més tard');
        } else if (error.request) {
          console.error('Error request:', error.request);
          setErrorMessage('No s’ha rebut cap resposta del servidor, torna-ho a provar més tard');
        } else {
          console.error('Error message:', error.message);
          setErrorMessage('Error en fer la petició, torna-ho a provar més tard');
        }
        setShowModal(true);
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className={styles.flexContainer}>
          <img src={Arrow} alt="arrow" className={styles.arrow} />
          <div>
            <p className={styles.paragraph}>Indica’ns el codi que trobaràs dins la capsa de subscripció</p>
            <div className={styles.inputButtonContainer}>
              <input
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className={styles.input}
              />
              {showModal && (
                <div className={styles.modal}>
                  <p className={styles.modalParagraph}>{errorMessage}</p>
                  <button onClick={handleCloseModal} className={styles.closeButton}>X</button>
                </div>
              )}
              <button className={styles.button}>Enviar</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default SubscriptionForm;
