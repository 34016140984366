import React from 'react';
import AbacusLogo from '../Assets/abacus-logo.png';
import styles from '../Styles/Navbar.module.css'; // Ensure you create this CSS module file

const Navbar = () => {
  return (

    <nav className={styles.navbar}>
      <div className={styles.navbarContainer}>
        <img src={AbacusLogo} alt="Abacus Logo" className={styles.logo} />
      </div>
    </nav>
  );
};

export default Navbar;
