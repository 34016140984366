import React from 'react';
import SubscriptionForm from './SubscriptionForm';
import Navbar from './Navbar';
import Background from '../Assets/background.png';
import styles from '../Styles/Home.module.css';

const Home = () => {
  return (
    <>
      <Navbar />
      <div className={styles.pageWrapper}>
        <div className={styles.paddingGlobal}>
          <div className={styles.container}>
            <div className={styles.contentWrapper}>
              <div className={styles.leftSection}>
                <h1 className={styles.title}>Activa la teva subscripció!</h1>
                <p className={styles.description}>Valida ara la teva subscripció a la revista que t'han regalat i gaudeix de 6 mesos de franc!</p>
                <SubscriptionForm />
              </div>
              <div className={styles.rightSection}>
                <img src={Background} alt="magazines background" className={styles.magazineImage} />
              </div>
            </div>
            <div class={styles.helpText}>
              <p class={styles.helpParagraph}>Necessites ajuda?</p>
              <p class={styles.helpParagraph}>Envia'ns un correu a <a href="mailto:subscripcions@abacus.coop" class="emailLink">subscripcions@abacus.coop</a> </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
